.label {
    font-size: 13px;
}

.buttonGroup {
    width: 100%;
    max-width: 228px;
    flex-wrap: wrap;
}

.button {
    max-width: 150px;
    font-size: 11px;
    color: black;
    background-color: white;
}

.button:focus,
.button:active {
    color: white !important;
    background-color: darkblue !important;
    box-shadow: none !important;
}
