.label {
    font-size: 13px;
}

.buttons {
    padding-top: 5px;
    font-size: 10px !important;
}

.button {
    font-size: 13px;
}

.infoText {
    font-size: 11px;
    padding-top: 3px;
    white-space: normal;
}

.customLogo {
    width: 200px;
    height: 50px;
    margin-right: 10px;
}
