.name {
    padding-left: 10px;
    padding-bottom: 75px;
}

.nameInput {
    font-size: 25px;
    padding: 25px 5px;
    width: 90%;
    float: left;
    background: white !important;
}

input:read-only {
    border: none;
}

*:focus {
    box-shadow: none;
}

.editIcon {
    width: 30px;
    height: 30px;
    float: left;
    margin-top: 15px;
}
