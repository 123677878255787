.mainDiv {
    margin: 0 auto;
    vertical-align: top;
}

.toggleLink {
    font-size: 12px;
    font-weight: bold;
    color: #00b7f0;
    float: right;
    padding-right: 100px;
    padding-top: 10px;
    cursor: pointer;
}

.leftPanel {
    width: 50%;
    float: left;
    text-align: center;
}

.leftPanel > iframe {
    height: 600px;
    width: 500px;
}

.rightPanel {
    width: 50%;
    float: right;
    border-left: 5px lightgrey outset;
    border-top: 5px lightgrey outset;
    padding-left: 10px;
}

.namePanel {
    width: 50%;
    float: left;
}
