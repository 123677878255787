.searchGroup {
    margin-bottom: 20px;
    width: 100%;
}

.label {
    font-size: 13px;
    display: block;
}

.imagePrepend {
    border: thin lightgrey solid;
    background-color: white;
    padding: 5px;
}

.searchIcon {
    width: 18px;
    height: 18px;
    text-align: center;
}

.searchBox {
    border-left: none;
    height: 30px;
}
