.backBtn {
    padding-top: 20px;
    padding-right: 70px;
    float: left;
}

.actionBtn {
    padding-top: 20px;
    padding-right: 70px;
    float: right;
}
