.label {
    font-size: 13px;
}

.select {
    font-size: 13px;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 80%;
    max-width: 228px;
    border-radius: 3px;
}

.option {
    font-size: 13px;
}
