.dropdownToggle {
    background-color: transparent;
    outline: none;
    border: none;
    box-shadow: none;

    &:hover {
        background-color: transparent;
        outline: none;
    }

    &:active {
        background-color: transparent !important;
    }
}

.dropdownMenu {
    padding: 0;

    > div {
        margin: 0;
    }
}

.moreInfoIcon {
    width: 20px;
    height: 20px;
}
