.activeStepOne {
    font-size: 13px;
    color: #00b7f0;
}

.inactiveStepOne {
    font-size: 13px;
    color: darkgray;
}

.activeStepTwo {
    font-size: 13px;
    color: #00b7f0;
    padding-left: 15px;
}

.inactiveStepTwo {
    font-size: 13px;
    color: darkgray;
    padding-left: 15px;
}

.bottom {
    border-bottom: 1px solid lightgrey;
    width: 88%;
    margin-bottom: 10px;
    margin-left: 12px;
}
