.themeCardHeader {
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 10px;
    background-color: #ffffff;
}

.themeCardHeaderButton {
    margin-left: auto;
    border-left: 1px solid #cbcbcb;
}

.themeCardBody {
    display: flex;
    padding: 0;
    height: 150px;

    > div {
        width: 50%;
    }
}

.themeCardBodyRightPanel {
    > div {
        height: 33.33%;
    }
}

.logo {
    height: 90%;
    width: 90%;
    overflow: hidden;
    padding: 5px;
    margin: auto;
    display: block;
    object-fit: scale-down;
}
