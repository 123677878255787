// Global colour variables
$rd-web-blue: #00b7f0;

// Global Bootstrap Overrides
$font-size-base: 0.8125rem; // 13px
$h1-font-size: 1.875rem; // 30px
$btn-box-shadow: none;
$btn-focus-width: 0;
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;

// Button (Background) Colours
$primary: $rd-web-blue;

// Bootstrap Styles
@import './bootstrap.scss';
