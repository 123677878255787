.label {
    font-size: 13px;
}

.popover {
    position: absolute;
    z-index: 2;
}

.cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.outerDiv {
    white-space: nowrap;
}

.colourWell {
    width: 30%;
    max-width: 30px;
    height: 30px;
    display: inline-block;
    vertical-align: top;
    border: thin solid grey;
    border-radius: 3px 0 0 3px;
}

.colourInput {
    vertical-align: top;
    text-align: right;
    width: 70%;
    max-width: 150px;
    min-width: 80px;
    height: 30px;
    padding-right: 10px;
    border: thin solid grey;
    border-left: none;
    border-radius: 0 3px 3px 0;
}
