.card {
    border: 0;
    width: 90%;
    padding-left: 10px;
}
.cardHeader {
    font-weight: bold;
    font-size: 13px;
    background-color: white;
    padding-left: 0;
}

.cardBody {
    background-color: #f8f8f8;
    border: 1px solid darkgray;
    border-radius: 3px;
}
