.themeListingHeader {
    display: flex;
    width: 100vw;
    margin-bottom: 20px;
}

.themeListingHeaderButton {
    margin-left: auto;
    align-self: center;
}
