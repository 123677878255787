.label {
    font-size: 13px;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    max-width: 125px;
    height: 1px;
    border-radius: 5px;
    background: darkgray;
    outline: none;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border: 1px darkgray solid;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 15px;
    height: 15px;
    border: 1px darkgray solid;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
}
