.label {
    font-size: 13px;
    padding-bottom: 10px;
}

.formGroup {
    white-space: normal;
}

.radioButton {
    width: 200px;
    font-size: 13px;
}

.whiteLogo {
    border: thin lightgrey solid;
    background: white;
    padding: 5px;
}

.blackLogo {
    background: black;
    padding: 5px;
}
